import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { useCookies } from 'vue3-cookies'
import axios from 'axios'
import { GetItemsResponse, IUser, IUserAuth } from '@/types/interfaces'

const { cookies } = useCookies()

class UserAPIModel {
  /**
   * Регистрация пользователя в зависимости от роли
   * @param data
   * @param apiModal - Farmer или Customer
   */
  async registerUser(data: any, apiModal: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.create(Microservices.Core, apiModal, data)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Регистрация пользователя по инвайту
  async inviteRegister(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/inviteRegister`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async dropPassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/dropPassword`, {
          attributes: data,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async confirmDropPassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/confirmDropPassword`, {
          attributes: data,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async confirmRegistration(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/confirmRegistration`, {
          attributes: data,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async resendEmail(data: { email: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Auth,
        APIModels.User,
        'resendEmail',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async resendConfirmEmail(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/resendEmail`, {
          attributes: data,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async getUser(id: string, withs: string[] = []): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.User,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getItemsCore(
    page = 1,
    perPage = 10,
    filters: (string | number)[][] = [],
    withs: string[] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(Microservices.Core, APIModels.User)
        .withs(withs)
        .setPagination(perPage, page)
        .filter(filters)
        .order([['created_at', 'desc']])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  async getItemAuth(id: string, withs: string[] = []): Promise<IUserAuth> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Auth,
        APIModels.User,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getUserList(
    withs: string[] = [],
    pagination = { perPage: 10, page: 1 },
    filters: (string | string[])[][] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(Microservices.Auth, APIModels.User)
        .withs(withs)
        .setPagination(pagination.perPage, pagination.page)
        .order([['created_at', 'desc']])
        .filter(filters)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getUserData(
    id: string | number,
    apiModal: string,
    withs: string[] = [],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(Microservices.Core, apiModal, id)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateUserDataRegister(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          Authorization: cookies.get('core'),
        },
      }
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/User/update`,
          {
            ...data,
          },
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async updateUserData(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          Authorization: cookies.get('core'),
        },
      }
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/User/update`,
          {
            attributes: {
              ...data,
            },
          },
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async updateRoleUserData(data: any, apiModal: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.update(Microservices.Core, apiModal, data)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateContactDescription(
    data: {
      id: number
      description: string
    },
    apiModal: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.update(Microservices.Core, apiModal, data)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateEmail(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/changeEmail`, {
          attributes: data,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async updatePassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Auth,
        APIModels.User,
        'changePassword',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCaptcha(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/auth/User/getCaptcha`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const UserAPI = new UserAPIModel()
export { UserAPI }
